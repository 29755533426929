import { FC } from 'react';
import styles from './PageHeading.module.scss';

interface IPageHeading {
  title: string;
  extraClassName?: string;
}

const PageHeading: FC<IPageHeading> = ({ title, extraClassName }) => {
  const getClassName = () => (extraClassName ? `${styles.pageHeading} ${styles[`${extraClassName}`]}` : styles.pageHeading);

  return (
    <div className={getClassName()}>
      <div className={styles.pageHeading__txt}>
        <h1>{title}</h1>
      </div>
    </div>
  );
};

export default PageHeading;
