import { FC } from 'react';
import Link from 'next/link';
import styles from './BookMidBtn.module.scss';

interface IBookMidBtn {
  txt: string;
  href: string;
  target?: string;
  rel?: string;
  extraClassName?: string;
}

const BookMidBtn: FC<IBookMidBtn> = ({ txt, href, extraClassName, target, rel }) => {
  const getClassName = () => (extraClassName ? `${styles.bookMidBtn} ${styles[`${extraClassName}`]}` : styles.bookMidBtn);

  return (
    <Link prefetch={false} href={href} className={getClassName()} target={target} rel={rel}>
      {txt}
    </Link>
  );
};

export default BookMidBtn;
