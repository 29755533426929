import Layout from '@/components/layout/Layout';
import { FC } from 'react';
import SectionTitle from '@/components/ui/section-title/SectionTitle';
import SectionTitleMenu from '@/components/ui/section-title-menu/SectionTitleMenu';
import BookCardItem from '@/components/ui/book-card-item/BookCardItem';
import PageHeading from '@/components/ui/page-heading/PageHeading';
import BookMidBtn from '@/components/ui/book-mid-btn/BookMidBtn';
import styles from './Error.module.scss';
import { IBooksPageData } from '@/interfaces/booksPage.interface';
import { ISideBarData } from '@/interfaces/sidebar.interface';

export interface IError extends ISideBarData {
  books: IBooksPageData[];
}

const Error: FC<IError> = ({ books, sidebarGenres }) => {
  //Seo block
  const SEO_TITLE = 'Ошибка 404';
  const SEO_DESCRIPTION = 'Запрошенной страницы нет на нашем сайте.Возможно, вы ввели неправильный адрес в строке браузера.';
  const SEO_CANONICAL = '/error/';

  return (
    <Layout title={SEO_TITLE} description={SEO_DESCRIPTION} canonicalHref={SEO_CANONICAL} pageHref={SEO_CANONICAL} sidebarGenres={sidebarGenres} isDefaultPopularSlider={false}>
      <div className={styles.errorBlock}>
        <div className={styles.errorBlock__img}></div>
        <div className={styles.errorBlock__desk}>
          <div className={styles.errorBlock__title}>
            <PageHeading title="Извините, такой страницы нет…" extraClassName="pageHeading_plain" />
          </div>
          <div className={styles.errorBlock__txt}>Попробуйте вернуться назад и повторить еще раз, или выберите из предложенного ниже.</div>
          <BookMidBtn href="/" txt="Перейти на главную" extraClassName="bookMidBtn_mobFull" />
        </div>
      </div>

      <SectionTitleMenu extraClassName={'sectionTitleMenu_mainPage'}>
        <SectionTitle name={'Популярные книги'} extraClassName={'sectionHeading_plain'} />
      </SectionTitleMenu>
      <div data-cards="list" className="cardsWrapper">
        {books.length ? books.map((book: IBooksPageData) => <BookCardItem alias={book.alias} author={book.author} book={book.book} rating={book.rating} key={book.id} draft={book.draft} readOnline={book.read_online} />) : <div>Книги не найдены</div>}
      </div>
    </Layout>
  );
};

export default Error;
