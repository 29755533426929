import { NextPage } from 'next';
import { GetStaticProps } from 'next';
import Error from '@/components/screens/error/Error';
import { POPULAR_BOOKS, API_URL, REVALIDATE_TIME, SIDEBAR_API } from '@/helpers/variables';
import { IBooksPageData } from '@/interfaces/booksPage.interface';
import { ISideBarData } from '@/interfaces/sidebar.interface';

export interface INotFound extends ISideBarData {
  books: IBooksPageData[];
}

const NotFound: NextPage<INotFound> = ({ books, sidebarGenres }) => {
  return <Error books={books} sidebarGenres={sidebarGenres} />;
};

export const getStaticProps: GetStaticProps = async () => {
  const res = await fetch(`${API_URL}satellite/books/popularity?page=1&page_size=${POPULAR_BOOKS}`);
  const books = await res.json();

  //сайдбар
  const sidebarGenresRes = await fetch(`${SIDEBAR_API.genres}`);
  const sidebarGenres = await sidebarGenresRes.json();

  return {
    props: { books, sidebarGenres },
    revalidate: REVALIDATE_TIME,
  };
};

export default NotFound;
